export class Login {
    static readonly type = '[Auth] Login';

    constructor(public username: string, public password: string, public rememberMe: boolean) {
    }
}

export class LoginByToken {
    static readonly type = '[Auth] LoginByToken';

    constructor(public token: string) {}
}

export class Logout {
    static readonly type = '[Auth] Logout';
}
