import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import {Observable} from 'rxjs';
import {AuthState} from "../state/auth.state";
import {Store} from "@ngxs/store";

@Injectable({
  providedIn: 'root'
})
export class AuthGuard  {

    constructor(
        private store: Store,
        private router: Router) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        const isAuthenticated = this.store.selectSnapshot(AuthState.isAuthenticated);
        if (isAuthenticated) {
            return true;
        } else {
            return this.router.createUrlTree(["auth", "login"]);
        }
    }
}
