import {Injectable} from '@angular/core';

import {Apollo, gql} from "apollo-angular";
import {map, Observable} from "rxjs";
import {PageInput, PageResult} from "../../base/domain/page.model";
import {AssetLocation, AssetLocationId} from "../domain/asset-location.model";

import {AssetBuilding, AssetBuildingId} from "../domain/asset-building.model";
import {AssetTreeNode} from "../domain/asset-tree-node.model";

@Injectable({
    providedIn: 'root'
})
export class AssetBuildingService {

    constructor(private apollo: Apollo) {
    }

    getBuildingsForLocation(assetLocationId: AssetLocationId): Observable<Array<AssetBuilding>> {
        return this.apollo.query<{ assetLocation: AssetLocation }>({
            query: gql`query assets($id: String!){
                assetLocation(id: $id) {
                    buildings {
                        id
                        name
                        region
                        location
                        urn
                        geoLatitude
                        geoLongitude
                    }
                }
            }`,
            variables: {
                id: assetLocationId
            }
        }).pipe(map(o => o.data.assetLocation?.buildings ?? []));
    }


    getBuildings(page?: PageInput): Observable<PageResult<AssetBuilding>> {
        if (page == null) {
            page = {
                page: 0
            }
        }
        return this.apollo.query<{ assetBuildings: PageResult<AssetBuilding> }>({
            query: gql`query assets($page: PageInput!){
                assetBuildings(page: $page) {
                    total
                    page
                    pageSize
                    hasMore
                    content {
                        id
                        name
                        region
                        city
                        address
                        location
                        urn
                        geoLatitude
                        geoLongitude
                        assetLocation {
                            id
                            city
                            address
                            geoLatitude
                            geoLongitude
                            name
                            rds
                        }
                    }
                }
            }`,
            variables: {
                page: page
            }
        }).pipe(map(o => o.data.assetBuildings));
    }

    getBuilding(assetBuildingId: AssetLocationId): Observable<AssetBuilding> {
        return this.apollo.query<{ assetBuilding: AssetBuilding }>({
            query: gql`query assetBuilding($id: String!){
                assetBuilding(id: $id) {
                    name
                    urn
                    location
                    region
                    id
                    geoLatitude
                    geoLongitude
                    metadata
                    assetLocation {
                        id
                        name
                        address
                    }
                }
            }`,
            variables: {
                id: assetBuildingId
            }
        }).pipe(map(o => o.data.assetBuilding));
    }


    getBuildingProperties(assetBuildingId: AssetBuildingId): Observable<any> {
        return this.apollo.query<{ assetBuilding: AssetBuilding }>({
            query: gql`query assets($id: String!){
                assetBuilding(id: $id) {
                    name
                    urn
                    location
                    modelProperties
                }
            }`,
            variables: {
                id: assetBuildingId
            }
        }).pipe(map(o => o.data.assetBuilding?.modelProperties?.data?.collection ?? []));
    }

    getBuildingMetadata(assetBuildingId: AssetBuildingId): Observable<any> {
        return this.apollo.query<{ assetBuilding: AssetBuilding }>({
            query: gql`query assets($id: String!){
                assetBuilding(id: $id) {
                    name
                    urn
                    location
                    modelMetadata
                }
            }`,
            variables: {
                id: assetBuildingId
            }
        }).pipe(map(o => o.data.assetBuilding?.modelMetadata ?? []));
    }

    getBuildingModelTree(assetBuildingId: AssetBuildingId): Observable<any> {
        return this.apollo.query<{ assetBuilding: AssetBuilding }>({
            query: gql`query assets($id: String!){
                assetBuilding(id: $id) {
                    name
                    urn
                    location
                    modelTree
                }
            }`,
            variables: {
                id: assetBuildingId
            }
        }).pipe(map(o => o.data.assetBuilding?.modelTree ?? []));
    }


    assetBuildingTree(): Observable<Array<AssetTreeNode>> {
        return this.apollo.query<{ assetBuildingTree: Array<AssetTreeNode> }>({
            query: gql`query assetBuildingTree{
                assetBuildingTree {
                    key
                    title
                    geoLatitude
                    geoLongitude
                    assetType
                    isLeaf
                    children {
                        key
                        title
                        geoLatitude
                        geoLongitude
                        assetType
                        isLeaf
                    }
                }
            }`
        }).pipe(map(o => o.data?.assetBuildingTree ?? []));
    }
}
