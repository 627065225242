import {NgModule, DEFAULT_CURRENCY_CODE, LOCALE_ID, Injector} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {AppLayoutModule} from "./app-layout/app-layout.module";
import {NgxsModule} from "@ngxs/store";
import {environment} from "../environments/environment";
import {AuthState} from "./auth/state/auth.state";
import {NgxsReduxDevtoolsPluginModule} from "@ngxs/devtools-plugin";
import {NgxsStoragePluginModule} from "@ngxs/storage-plugin";
import {GraphQLModule} from "./graphql.module";
import {ViewerModule} from "./viewer/viewer.module";
import {ApolloModule} from "apollo-angular";
import {HttpClientModule} from "@angular/common/http";
import {IconsModule} from "./theme/icons/icons.module";
import {de_DE, NZ_I18N} from 'ng-zorro-antd/i18n';
import {NZ_CONFIG} from "ng-zorro-antd/core/config";
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
import {registerLocaleData} from '@angular/common';
import {RdsState} from "./rds/state/rds.state";


registerLocaleData(localeDe, localeDeExtra);

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        AppLayoutModule,
        IconsModule,
        NgxsModule.forRoot([AuthState, RdsState], {
            developmentMode: !environment.production
        }),
        NgxsStoragePluginModule.forRoot({key: "auth"}),
        NgxsReduxDevtoolsPluginModule.forRoot(),
        ApolloModule,
        HttpClientModule,
        GraphQLModule,
        ViewerModule
    ],
    providers: [
        {provide: LOCALE_ID, useValue: 'de'},
        {provide: DEFAULT_CURRENCY_CODE, useValue: 'EUR'},
        {provide: NZ_I18N, useValue: de_DE},
        // {provide: NZ_CONFIG, useFactory: nzConfigFactory, deps: [Injector, ComponentFactoryResolver]}
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
