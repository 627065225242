export namespace Rds {

    export class LoadAspectFunctions {
        static readonly type = '[Rds] LoadAspectFunctions';
    }

    export class LoadAspectSystems {
        static readonly type = '[Rds] LoadAspectSystems';
    }

    export class LoadAspectLocation {
        static readonly type = '[Rds] LoadAspectLocation';
    }


}
