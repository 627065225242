import {AfterContentInit, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from "rxjs";
import {ViewerForgeService} from "../service/viewer-forge.service";
import {ForgeViewer3D, LoadedDocument} from "../domain/viewer-forge.model";
import {PortfolioState} from "../../portfolio/state/portfolio.state";
import {Store} from "@ngxs/store";
import {RdsFilter} from "../../base/domain/rds.model";

@Component({
    selector: 'dh-viewer-forge',
    templateUrl: './viewer-forge.component.html',
    styles: [`

        @import 'https://developer.api.autodesk.com/modelderivative/v2/viewers/7.*/style.min.css';

        #forge-viewer {
            width: 100%;
            height: 100%;
            margin: 0;
        }`]
})
export class ViewerForgeComponent implements OnInit, OnDestroy {

    @Input()
    urn: string;

    loading = true;
    @Input()
    isolateBySelected = false;
    viewer?: ForgeViewer3D;
    private subscriptions = new Subscription();

    selectedAsset$ = this.store.select(PortfolioState.selectedAsset)

    constructor(private viewerForgeService: ViewerForgeService, private store: Store) {

    }

    ngOnInit(): void {

        this.subscriptions.add(this.selectedAsset$.subscribe(s => {
            if(this.isolateBySelected) {
                if (s) {
                    // aktuell nur für dynamowerk
                    if(s.title.startsWith("DE-BLNN3")) {
                        let filter: RdsFilter = new RdsFilter();
                        let code = s.title.substring(9, 13);
                        filter.rdsBuilding = code;
                        this.applyFilter(filter)
                    }
                }else{
                    this.viewer?.showAll();
                }
            }
        }))

        this.initViewer().then(async () => {
            let loadedDoc = await this.loadDocument(this.urn);
            await this.loadRootModels([loadedDoc]);
            this.loading = false;
        })
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
        if (this.viewer != null) {
            this.viewer.finish();
            this.viewer = null;
            window.Autodesk.Viewing.shutdown();
        }
    }

    private applyFilter(filter: RdsFilter) {
        // @ts-ignore
        this.viewer?.search(filter.rdsBuilding, ids => {
            console.log(ids);
            this.viewer?.isolate(ids);
        }, ()=> {
            console.log("error");
        }, ["RDS_Gebaeude"]);

    }


    private loadDocument(urn: string): Promise<LoadedDocument> {
        return new Promise((resolve, reject) => {
            window.Autodesk.Viewing.Document.load(`urn:${urn}`, (viewerDocument) => {
                resolve({urn: urn, model: viewerDocument});
            }, (errorCode, errorMsg, messages) => {
                reject(({errorCode, errorMsg, messages}));
            });
        });
    }

    loadRootModels(loadedDocuments: LoadedDocument[]) {
        const values = loadedDocuments.map(({model}) => {
            const first3dGeometry = model.getRoot().search({role: '3d', type: 'geometry'})[0];
            const defaultGeometry = model.getRoot().getDefaultGeometry();
            let options = {
                globalOffset: new THREE.Vector3(0, 0,0),
                // fix for different scale of models, like revit and dgn
                applyScaling: 'm'
            }
            if (this.viewer == null) {
                console.error("Viewer is null");
            }
            return this.viewer?.loadDocumentNode(model, first3dGeometry || defaultGeometry, options);
        });
        return Promise.all(values);
    }

    async initViewer() {
        try {
            this.viewer = await this.viewerForgeService.initializeViewer();
        } catch (e) {
            console.error(e);
            // this.coreService.notificationService.error(this.coreService.translate('files.forge.failed_initialize'));
        }
    }

    private onResize() {
        setTimeout(() => {
            this.viewer?.resize()
        }, 500)
    }

}
