import {Action, Selector, State, StateContext} from "@ngxs/store";
import {Injectable} from "@angular/core";
import {AuthService} from "../service/auth.service";
import {Login, LoginByToken, Logout} from "./auth.actions";
import {tap} from "rxjs";
import {AuthStateModel} from "../domain/auth-state.model";
import {Router} from "@angular/router";

@State<AuthStateModel>({
    name: 'auth',
    defaults: {
        token: null,
        username: null
    }
})
@Injectable()
export class AuthState {


    constructor(private authService: AuthService, private router: Router) {
    }



    @Selector()
    static token(state: AuthStateModel): string | null {
        return state.token;
    }

    @Selector()
    static isAuthenticated(state: AuthStateModel): boolean {
        return !!state.token;
    }

    @Action(Login)
    login(ctx: StateContext<AuthStateModel>, action: Login) {
        return this.authService.login(action.username, action.password, action.rememberMe).pipe(
            tap((result: { token: string }) => {
                ctx.patchState({
                    token: result.token,
                    username: action.username
                });
            })
        );
    }

    @Action(LoginByToken)
    loginByToken(ctx: StateContext<AuthStateModel>, action: LoginByToken) {
        console.log("Login by token", action.token);
        return this.authService.loginByToken(action.token).pipe(
            tap((result: { token: string, username: string }) => {
                ctx.patchState({
                    token: action.token,
                    username: result.username
                });
            })
        );
    }

    @Action(Logout)
    logout(ctx: StateContext<AuthStateModel>) {
        ctx.setState({
            token: null,
            username: null
        });
        this.router.navigate(["/", "auth", "login"]);
    }
}
