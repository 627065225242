import {Injectable} from '@angular/core';

import {Apollo, gql} from "apollo-angular";
import {map, Observable} from "rxjs";
import {PageInput, PageResult} from "../../base/domain/page.model";
import {AspectFunction, AspectFunctionId} from "../domain/aspect-function.model";


@Injectable({
    providedIn: 'root'
})
export class AspectFunctionService {

    constructor(private apollo: Apollo) {
    }

    getAspectFunctions(page?: PageInput): Observable<PageResult<AspectFunction>> {
        if (page == null) {
            page = {
                page: 0
            }
        }
        return this.apollo.query<{ aspectFunctions: PageResult<AspectFunction> }>({
            query: gql`query aspectFunctions($page: PageInput!){
                aspectFunctions(page: $page) {
                    total
                    page
                    pageSize
                    hasMore
                    content {
                        id
                        name
                        aspectGroup
                        aspectValue
                    }
                }
            }`,
            variables: {
                page: page
            }
        }).pipe(map(o => o.data.aspectFunctions));
    }

    getAspectFunction(aspectFunctionId: AspectFunctionId): Observable<AspectFunction> {
        return this.apollo.query<{ aspectFunction: AspectFunction }>({
            query: gql`query aspectFunctions($id: String!){
                aspectFunction(id: $id) {
                    id
                    name
                    aspectValue
                    aspectGroup

                }
            }`,
            variables: {
                id: aspectFunctionId
            }
        }).pipe(map(o => o.data.aspectFunction));
    }

}
