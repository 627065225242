export class RdsFilter {

    rdsCountry?: string
    rdsLocation?: string
    rdsBuilding?: string
    rdsFloor?: string
    rdsRoom?: string
    rdsSegment?: string
    rdsSystem?: string
    rdsSystemId?: string
    rdsSystemNum?: string
    rdsFunction?: string
    rdsFunctionNum?: string
    rdsType?: string


    toListFilter(): RdsListFilter {
        let list = new RdsListFilter();
        list.rdsCountry = this.rdsCountry ? [this.rdsCountry] : [];
        list.rdsLocation = this.rdsLocation ? [this.rdsLocation] : [];
        list.rdsBuilding = this.rdsBuilding ? [this.rdsBuilding] : [];
        list.rdsFloor = this.rdsFloor ? [this.rdsFloor] : [];
        list.rdsRoom = this.rdsRoom ? [this.rdsRoom] : [];
        list.rdsSegment = this.rdsSegment ? [this.rdsSegment] : [];
        list.rdsSystem = this.rdsSystem ? [this.rdsSystem] : [];
        list.rdsSystemId = this.rdsSystemId ? [this.rdsSystemId] : [];
        list.rdsSystemNum = this.rdsSystemNum ? [this.rdsSystemNum] : [];
        list.rdsFunction = this.rdsFunction ? [this.rdsFunction] : [];
        list.rdsFunctionNum = this.rdsFunctionNum ? [this.rdsFunctionNum] : [];
        list.rdsType = this.rdsType ? [this.rdsType] : [];
        return list;
    }

}

export class RdsListFilter {

    rdsCountry?: Array<string>;
    rdsLocation?: Array<string>;
    rdsBuilding?: Array<string>;
    rdsFloor?: Array<string>;
    rdsRoom?: Array<string>;
    rdsSegment?: Array<string>;
    rdsSystem?: Array<string>;
    rdsSystemId?: Array<string>;
    rdsSystemNum?: Array<string>;
    rdsFunction?: Array<string>;
    rdsFunctionNum?: Array<string>;
    rdsType?: Array<string>;

    static compare(left: RdsListFilter, right: RdsListFilter) {
        if (left == null) {
            return right == null;
        }
        if(!RdsListFilter.arraysEqual(left.rdsLocation, right?.rdsLocation)){
            return false;
        }
        if(!RdsListFilter.arraysEqual(left.rdsBuilding, right?.rdsBuilding)){
            return false;
        }
        if(!RdsListFilter.arraysEqual(left.rdsFloor, right?.rdsFloor)){
            return false;
        }
        if(!RdsListFilter.arraysEqual(left.rdsRoom, right?.rdsRoom)){
            return false;
        }
        if(!RdsListFilter.arraysEqual(left.rdsSegment, right?.rdsSegment)){
            return false;
        }
        if(!RdsListFilter.arraysEqual(left.rdsSystem, right?.rdsSystem)){
            return false;
        }
        if(!RdsListFilter.arraysEqual(left.rdsFunction, right?.rdsFunction)){
            return false;
        }
        return true;
    }

    private static arraysEqual(a: string[] | undefined, b: string[] | undefined) {
        if (a === b) return true;
        if (a == null || b == null) return false;
        if (a.length !== b.length) return false;
        let aSorted = [...a].sort();
        let bSorted = [...b].sort();
        for (let i = 0; i < aSorted.length; ++i) {
            if (aSorted[i] !== bSorted[i]) return false;
        }
        return true;
    }
}
