import {Action, Selector, State, StateContext} from "@ngxs/store";
import {Injectable, OnDestroy} from "@angular/core";
import {AssetBuildingService} from "../service/asset-building.service";
import {Portfolio} from "./portfolio.actions";
import {Subscription, tap} from "rxjs";
import {Router} from "@angular/router";
import {AssetLocation, AssetLocationId} from "../domain/asset-location.model";
import {AssetLocationService} from "../service/asset-location.service";
import {PortfolioStateModel} from "./portfolio-state.model";
import {patch, updateItem} from "@ngxs/store/operators";
import {AssetBuilding} from "../domain/asset-building.model";
import {AssetTreeNode} from "../domain/asset-tree-node.model";

@State<PortfolioStateModel>({
    name: 'portfolio',
    defaults: {
        buildings: new Array<AssetTreeNode>(),
        locations: new Array<AssetLocation>(),
        tree: new Array<AssetTreeNode>(),
        filteredBuildings: new Array<AssetTreeNode>(),
        selectedAsset: null
    }
})
@Injectable()
export class PortfolioState implements OnDestroy {

    private subscriptions = new Subscription();

    constructor(private assetBuildingService: AssetBuildingService,
                private assetLocationService: AssetLocationService,
                private router: Router) {

    }


    @Selector()
    static locations(state: PortfolioStateModel) {
        return state?.locations ?? [];
    }

    @Selector()
    static buildings(state: PortfolioStateModel) {
        return state?.buildings ?? [];
    }

    @Selector()
    static portfolio(state: PortfolioStateModel) {
        return state?.tree ?? [];
    }

    @Selector()
    static filteredBuildings(state: PortfolioStateModel) {
        return state?.filteredBuildings ?? [];
    }

    @Selector()
    static selectedAsset(state: PortfolioStateModel) {
        return state?.selectedAsset;
    }

    @Selector()
    static getLocation(state: PortfolioStateModel) {
        return (locationId: AssetLocationId) => {
            return state?.locations.find(l => l.id == locationId) ?? null;
        }
    }


    @Action(Portfolio.LoadPortfolio)
    loadPortfolio(ctx: StateContext<PortfolioStateModel>) {
        return this.assetBuildingService.assetBuildingTree().pipe(tap(nodes => {
            let buildings: AssetTreeNode[] = [];
            nodes.forEach(loc => {
                buildings.push(...loc.children ?? []);
            })
            ctx.patchState({tree: nodes, buildings: buildings, filteredBuildings: buildings})
        }));
    }


    @Action(Portfolio.LoadLocations)
    loadLocations(ctx: StateContext<PortfolioStateModel>) {
        return this.assetLocationService.getLocations().pipe(tap(locations => {
            ctx.patchState({locations: locations})
        }));
    }


    @Action(Portfolio.SelectAsset)
    selectLocation(ctx: StateContext<PortfolioStateModel>, action: Portfolio.SelectAsset) {
        ctx.patchState({selectedAsset: JSON.parse(JSON.stringify(action.asset))});
    }

    @Action(Portfolio.FilteredBuildingsReset)
    filteredBuildingsReset(ctx: StateContext<PortfolioStateModel>, action: Portfolio.FilteredBuildingsReset) {
        ctx.patchState({filteredBuildings: [...ctx.getState().buildings], selectedAsset: null});
    }

    @Action(Portfolio.FilteredBuildings)
    filteredBuildings(ctx: StateContext<PortfolioStateModel>, action: Portfolio.FilteredBuildings) {
        ctx.patchState({filteredBuildings: ctx.getState().buildings.filter(b => action.buildings.includes(b.key ?? ""))});
    }

    @Action(Portfolio.OpenLocation)
    openLocation(ctx: StateContext<PortfolioStateModel>, action: Portfolio.OpenLocation) {
        this.router.navigateByUrl("/portfolio/location/" + action.locationId);
    }

    @Action(Portfolio.OpenBuilding)
    openBuilding(ctx: StateContext<PortfolioStateModel>, action: Portfolio.OpenBuilding) {
        this.router.navigateByUrl("/portfolio/building/" + action.buildingId);
    }

    @Action(Portfolio.OpenObject)
    openObject(ctx: StateContext<PortfolioStateModel>, action: Portfolio.OpenObject) {
        this.router.navigateByUrl("/portfolio/object/" + action.assetObjectId);
    }


    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
