import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ViewerForgeComponent} from './viewer-forge/viewer-forge.component';
import {GraphQLModule} from "../graphql.module";
import { ViewerComponent } from './viewer/viewer.component';
import {NzAlertModule} from "ng-zorro-antd/alert";


@NgModule({
    declarations: [
        ViewerForgeComponent,
        ViewerComponent
    ],
    exports: [
        ViewerForgeComponent,
        ViewerComponent
    ],
    imports: [
        CommonModule,
        GraphQLModule,
        NzAlertModule
    ]
})
export class ViewerModule {
}
