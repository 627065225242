import {AssetLocation, AssetLocationId} from "../domain/asset-location.model";
import {AssetBuilding, AssetBuildingId} from "../domain/asset-building.model";
import {Asset} from "../domain/asset.model";
import {AssetTreeNode} from "../domain/asset-tree-node.model";
import {AssetObjectId} from "../../asset-object/domain/asset-object.model";

export namespace Portfolio {

    export class LoadPortfolio {
        static readonly type = '[Portfolio] LoadPortfolio';
    }

    export class LoadLocations {
        static readonly type = '[Portfolio] LoadLocations';
    }

    export class LoadBuildings {
        static readonly type = '[Portfolio] LoadBuildings';
    }

    export class SelectAsset {
        static readonly type = '[Portfolio] SelectAsset';

        constructor(public asset: AssetTreeNode) {
        }
    }

    export class FilteredBuildings {
        static readonly type = '[Portfolio] FilteredBuildings';

        constructor(public buildings: Array<AssetBuildingId>) {
        }
    }

    export  class FilteredBuildingsReset {

        static readonly type = '[Portfolio] FilteredBuildingsReset';

    }

    export class OpenLocation {
        static readonly type = '[Portfolio] OpenLocation';

        constructor(public locationId: AssetLocationId) {
        }
    }

    export class OpenBuilding {
        static readonly type = '[Portfolio] OpenBuilding';

        constructor(public buildingId: AssetBuildingId) {
        }
    }

    export class OpenObject {
        static readonly type = '[Portfolio] OpenObject';

        constructor(public assetObjectId: AssetObjectId) {
        }
    }
}
