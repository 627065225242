import {Component, OnInit} from '@angular/core';
import {Store} from "@ngxs/store";
import {Rds} from "./rds/state/rds.actions";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

    constructor(private store: Store) {
    }

    ngOnInit() {
        this.store.dispatch(new Rds.LoadAspectFunctions());
        this.store.dispatch(new Rds.LoadAspectSystems());
        this.store.dispatch(new Rds.LoadAspectLocation());
    }
}
