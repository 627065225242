import {ApolloClientOptions, ApolloLink, InMemoryCache} from "@apollo/client/core";
import {HttpLink} from 'apollo-angular/http';
import {APOLLO_OPTIONS} from "apollo-angular";
import {AuthService} from "./auth/service/auth.service";
import {NgModule} from "@angular/core";
import {HttpHeaders} from "@angular/common/http";
import {onError} from "@apollo/client/link/error";
import {Store} from "@ngxs/store";
import {Logout} from "./auth/state/auth.actions";
import {AuthState} from "./auth/state/auth.state";

export function createApollo(httpLink: HttpLink, authService: AuthService, store: Store): ApolloClientOptions<any> {

    const http = httpLink.create({uri: '/graphql'});
    const middleware = new ApolloLink((operation, forward) => {
        let token = store.selectSnapshot(AuthState.token)
        let headers = new HttpHeaders();
        headers = headers.set('Authorization', `Bearer ${token || null}`);
        // console.log(translateService.currentLang);
        // headers = headers.set('Accept-Language', translateService.currentLang || "en");
        operation.setContext({
            headers: headers,
        });
        return forward(operation);
    });

    const tokenLink = middleware.concat(http);

    const errorLink = onError(({graphQLErrors, networkError}) => {
        if (graphQLErrors) {
            graphQLErrors.forEach(({message, locations, path}) =>
                console.trace(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
            );
        }
        let serverError = networkError as any;
        if (serverError?.status === 401 || serverError?.statusCode === 401) {
       //     store.dispatch(new Logout());
        } else {
            if (serverError?.status === 400 || serverError?.status === 403) {
                console.log(serverError)
            }
        }
    });

    const link = errorLink.concat(tokenLink);

    return {
        link,
        cache: new InMemoryCache({addTypename: false}),
        defaultOptions: {
            mutate: {fetchPolicy: 'no-cache', errorPolicy: 'all'},
            query: {fetchPolicy: 'no-cache', errorPolicy: 'all'},
            watchQuery: {fetchPolicy: 'no-cache', errorPolicy: 'all'},
        }
    };
}

@NgModule({
    providers: [
        {
            provide: APOLLO_OPTIONS,
            useFactory: createApollo,
            deps: [HttpLink, AuthService, Store],
        },
    ],
})
export class GraphQLModule {
}
