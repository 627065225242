import {NgModule} from '@angular/core';
import {NZ_ICONS, NzIconModule} from 'ng-zorro-antd/icon';
import {IconDefinition} from '@ant-design/icons-angular';
import {BellOutline, LogoutOutline, LoginOutline, ExportOutline, SearchOutline, StarOutline, PushpinOutline, DatabaseOutline,
EllipsisOutline, QrcodeOutline, LinkOutline, HomeOutline, GroupOutline, DownloadOutline, AuditOutline, TeamOutline} from '@ant-design/icons-angular/icons';

const icons: IconDefinition[] = [
    BellOutline, LogoutOutline, LoginOutline, ExportOutline, SearchOutline, StarOutline,
    PushpinOutline, DatabaseOutline, EllipsisOutline, QrcodeOutline, LinkOutline, HomeOutline, GroupOutline,
    DownloadOutline, AuditOutline, TeamOutline]

@NgModule({
    imports: [NzIconModule],
    exports: [NzIconModule],
    providers: [
        {provide: NZ_ICONS, useValue: icons}
    ]
})
export class IconsModule {
}
