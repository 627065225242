import {Injectable} from '@angular/core';
import {ViewerScriptService} from "./viewer-script.service";
import {ForgeViewer3D} from "../viewer-forge/autodesk-forge-extention";
import {Apollo, gql} from "apollo-angular";
import AggregatedViewInitOptions = Autodesk.Viewing.AggregatedViewInitOptions;


@Injectable({
    providedIn: 'root'
})
export class ViewerForgeService {

    constructor(private viewerScriptService: ViewerScriptService, private apollo: Apollo) {
    }

    async initializeViewer() {
        await this.viewerScriptService.load('forge-viewer');

        const options: Autodesk.Viewing.InitializerOptions = {
            env: 'AutodeskProduction',
            api: 'derivativeV2',  // for models uploaded to EMEA change this option to 'derivativeV2_EU'
            getAccessToken: this.fetchToken.bind(this),
            language: 'de'
            // language: this.coreService.currentLangGerman() ? 'de' : 'en',
        };

        return new Promise<ForgeViewer3D>((resolve, reject) => {

            window.Autodesk.Viewing.Initializer(options, () => {
                const htmlDiv = document.getElementById('forge-viewer');
                if (htmlDiv == null) {
                    return;
                }
                const view = new Autodesk.Viewing.AggregatedView();
                const options3d: AggregatedViewInitOptions = {
                    viewerConfig: {
                        disableBimWalkInfoIcon: true
                    }
                }
                view.init(htmlDiv, options3d);
                const viewer = view.viewer;
                this.loadDefaultExtensions(viewer);
                if (viewer.start() > 0) {
                    reject('Failed to create a Viewer: WebGL not supported.');
                } else {
                    resolve(viewer);
                }
            });
        });
    }

    async loadDefaultExtensions(viewer: ForgeViewer3D): Promise<void> {
        await viewer.loadExtension('Autodesk.DocumentBrowser');
        await viewer.loadExtension('Autodesk.GoHome');
        await viewer.loadExtension('Autodesk.Measure');
        await viewer.loadExtension('Autodesk.ModelStructure');
        await viewer.loadExtension('Autodesk.Explode');
        await viewer.loadExtension('Autodesk.FullScreen');
        await viewer.loadExtension('Autodesk.Viewing.FusionOrbit', {mode: 'fusionorbit'});
        await viewer.loadExtension('Autodesk.PropertiesManager');
    }

    fetchToken(onReady: (accessToken: string, expires: number) => void) {
        this.apollo.query<{ apps: any }>({
            query: gql`{
                apps {
                    forge {
                        token
                        expiresIn
                    }
                }
            }`
        }).subscribe(response => {
            let token = response.data.apps.forge.token;
            let expiresIn = response.data.apps.forge.expiresIn;
            onReady(token, expiresIn);
        })
    }
}
