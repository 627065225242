import {Injectable} from '@angular/core';
import {Router} from "@angular/router";
import {Observable, of, tap, throwError} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {LoginData} from "../domain/login-data.model";

@Injectable({
    providedIn: 'root'
})
export class AuthService {


    constructor(private http: HttpClient) {
    }


    login(username: string, password: string, rememberMe: boolean = false): Observable<{ token: string }> {
        const credentials = {
            username: username,
            password: password,
            rememberMe: rememberMe
        }
        return this.http.post<LoginData>("/api/authenticate", credentials);

    }

    loginByToken(token: string): Observable<{ token: string, username: string }> {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        let parsed = JSON.parse(jsonPayload);
        console.log(parsed);
        return of({token: token, username: parsed?.subject});
    }


}
