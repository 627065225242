import {Component, Input} from '@angular/core';

@Component({
    selector: 'dh-viewer',
    templateUrl: './viewer.component.html',
    host: {class: "flex-fill"},
    styles: []
})
export class ViewerComponent {

    @Input()
    urn: string | undefined;
    @Input()
    modelTitle: string = "Modell";

}
