<ul nz-menu nzMode="inline" class="sider-menu">
    <li nz-menu-item nzMatchRouter [routerLink]="['portfolio']">Portfolio</li>
    <li nz-menu-item nzMatchRouter [routerLink]="['parameters']">Parameter</li>
    <li nz-menu-item nzMatchRouter [routerLink]="['files']">Daten-Explorer</li>

    <li nz-submenu nzTitle="Auswertung" [nzOpen]="true">
        <ul>
            <li nz-menu-group nzTitle="Technisch"></li>
            <li nz-menu-item nzMatchRouter [routerLink]="['reports', 'tgm', 'asset-index']">Anlagenverzeichnis</li>
            <li nz-menu-item nzMatchRouter [routerLink]="['reports', 'tgm', 'asset-list']">Anlagenliste</li>
            <li nz-menu-group nzTitle="Infrastruktur"></li>
            <li nz-menu-item nzMatchRouter [routerLink]="['reports', 'igm', 'room-list']">Raumbuch</li>
            <li nz-menu-item nzMatchRouter [routerLink]="['reports', 'igm', 'outdoor-spaces']">Freiflächen</li>

            <li nz-menu-group nzTitle="ESG"></li>
            <li nz-menu-item nzMatchRouter [routerLink]="['reports', 'esg', 'rating']">Gebäudebewertung</li>
            <li nz-menu-item nzMatchRouter [routerLink]="['reports', 'esg', 'simulation']">Simulation</li>
        </ul>
    </li>

    <li nz-submenu nzTitle="Einstellungen">
        <ul>
            <li nz-menu-item nzMatchRouter [routerLink]="['admin', 'users']">Benutzer</li>
            <li nz-menu-item nzMatchRouter [routerLink]="['admin', 'parameters']">Parameter</li>
            <li nz-menu-item nzMatchRouter [routerLink]="['admin', 'reports']">Auswertungen</li>
            <li nz-menu-item nzMatchRouter [routerLink]="['admin', 'system']">System</li>
        </ul>
    </li>

</ul>
