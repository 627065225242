import {Component} from '@angular/core';

@Component({
    selector: 'dh-app-menu',
    templateUrl: './app-menu.component.html',
    host: {class: "flex-200"},
    styles: [`
      :host {
        background-color: #F3F3F0;
        padding-top: 15px;
      }
    `
    ]
})
export class AppMenuComponent {

}
