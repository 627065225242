import {Injectable} from '@angular/core';
import {Apollo, gql} from "apollo-angular";
import {map, Observable} from "rxjs";
import {AspectLocationSet} from "../domain/aspect-location-set.model";

@Injectable({
    providedIn: 'root'
})
export class AspectLocationService {

    constructor(private apollo: Apollo) {
    }


    getAspectLocation(): Observable<AspectLocationSet> {
        return this.apollo.query<AspectLocationSet>({
            query: gql`query aspectLocation{
                countries: aspectLocationCountries
                locations: aspectLocationLocations
                buildings: aspectLocationBuildings
                floors: aspectLocationFloors
                rooms: aspectLocationRooms
                segments: aspectLocationSegments
            }`
        }).pipe(map(o => o.data));
    }


    getAspectLocationCountries(): Observable<Array<string>> {
        return this.apollo.query<{ aspectLocationCountries: Array<string> }>({
            query: gql`query aspectLocationCountries{
                aspectLocationCountries
            }`
        }).pipe(map(o => o.data.aspectLocationCountries));
    }


    getAspectLocationLocations(): Observable<Array<string>> {
        return this.apollo.query<{ aspectLocationLocations: Array<string> }>({
            query: gql`query aspectLocationLocations{
                aspectLocationLocations
            }`
        }).pipe(map(o => o.data.aspectLocationLocations));
    }

    getAspectLocationBuildings(): Observable<Array<string>> {
        return this.apollo.query<{ aspectLocationBuildings: Array<string> }>({
            query: gql`query aspectLocationBuildings{
                aspectLocationBuildings
            }`
        }).pipe(map(o => o.data.aspectLocationBuildings));
    }

    getAspectLocationFloors(): Observable<Array<string>> {
        return this.apollo.query<{ aspectLocationFloors: Array<string> }>({
            query: gql`query aspectLocationFloors{
                aspectLocationFloors
            }`
        }).pipe(map(o => o.data.aspectLocationFloors));
    }

    getAspectLocationRooms(): Observable<Array<string>> {
        return this.apollo.query<{ aspectLocationRooms: Array<string> }>({
            query: gql`query aspectLocationRooms{
                aspectLocationRooms
            }`
        }).pipe(map(o => o.data.aspectLocationRooms));
    }

    getAspectLocationSegments(): Observable<Array<string>> {
        return this.apollo.query<{ aspectLocationSegments: Array<string> }>({
            query: gql`query aspectLocationSegments{
                aspectLocationSegments
            }`
        }).pipe(map(o => o.data.aspectLocationSegments));
    }


}
