import {Injectable} from '@angular/core';
import {Apollo, gql} from "apollo-angular";
import {map, Observable} from "rxjs";
import {AssetLocation, AssetLocationId} from "../domain/asset-location.model";

@Injectable({
    providedIn: 'root'
})
export class AssetLocationService {

    constructor(private apollo: Apollo) {
    }



    getLocations(): Observable<Array<AssetLocation>>{
        return this.apollo.query<{ assetLocations: Array<AssetLocation> }>({
            query: gql`query assetsLocations{
                assetLocations{
                    id
                    name
                    metadata
                    models {
                        urnCurrent
                        urnNew
                        urnOpenSpace
                        urnOutdoorFacilities
                        urnTerrain
                        urnMedia
                    }
                    city
                    district
                    address
                    geoLatitude
                    geoLongitude
                }
            }`
        }).pipe(map(o => o.data.assetLocations));
    }

    getLocation(assetLocationId: AssetLocationId): Observable<AssetLocation> {
        return this.apollo.query<{ assetLocation: AssetLocation }>({
            query: gql`query assets($id: String!){
                assetLocation(id: $id) {
                    id
                    name
                    rds
                    metadata
                    models {
                        urnCurrent
                        urnNew
                        urnOpenSpace
                        urnTerrain
                        urnOutdoorFacilities
                        urnMedia
                    }
                    address
                    district
                    city
                    geoLatitude
                    geoLongitude

                }
            }`,
            variables: {
                id: assetLocationId
            }
        }).pipe(map(o => o.data.assetLocation));
    }


}
