import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuard} from "./auth/guards/auth.guard";
import {AppLayoutComponent} from "./app-layout/app-layout/app-layout.component";

const routes: Routes = [
    {
        path: '', component: AppLayoutComponent,
        children: [
            // {path: '', loadChildren: () => import('./home/home.module').then(m => m.HomeModule)},
            {path: '', redirectTo: "portfolio", pathMatch: "full"},
            {path: 'portfolio', loadChildren: () => import('./portfolio/portfolio.module').then(m => m.PortfolioModule)},
            {path: 'files', loadChildren: () => import('./files/files.module').then(m => m.FilesModule)},
            {path: 'parameters', loadChildren: () => import('./parameters/parameters.module').then(m => m.ParametersModule)},
            {path: 'reports', loadChildren: () => import('./reports/reports.module').then(m => m.ReportsModule)},
            {path: 'aspect-systems', loadChildren: () => import('./aspect-systems/aspect-systems.module').then(m => m.AspectSystemsModule)},
            {path: 'aspect-functions', loadChildren: () => import('./aspect-function/aspect-function.module').then(m => m.AspectFunctionModule)},
            {path: 'aspect-attributes', loadChildren: () => import('./aspect-attribute/aspect-attribute.module').then(m => m.AspectAttributeModule)},
        ],
        canActivate: [AuthGuard]
    },
    {path: 'auth', loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule), canActivate: []},

];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
