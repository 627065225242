import {Action, Selector, State, StateContext} from "@ngxs/store";
import {Injectable} from "@angular/core";
import {mergeMap, tap} from "rxjs";
import {RdsStateModel} from "./rds-state.model";
import {AspectFunction} from "../../aspect-function/domain/aspect-function.model";
import {AspectSystem} from "../../aspect-systems/domain/aspect-system.model";
import {AspectFunctionService} from "../../aspect-function/service/aspect-function.service";
import {AspectSystemService} from "../../aspect-systems/service/aspect-system.service";
import {Rds} from "./rds.actions";
import {AspectLocationService} from "../../apsect-location/service/aspect-location.service";

@State<RdsStateModel>({
    name: 'rds',
    defaults: {
        functions: new Array<AspectFunction>(),
        systems: new Array<AspectSystem>(),
        rdsCountries: new Array<string>(),
        rdsLocations: new Array<string>(),
        rdsBuildings: new Array<string>(),
        rdsFloors: new Array<string>(),
        rdsRooms: new Array<string>(),
        rdsSegments: new Array<string>(),
    }
})
@Injectable()
export class RdsState {


    constructor(private aspectFunctionService: AspectFunctionService, private aspectSystemService: AspectSystemService,
                private aspectLocationService: AspectLocationService) {
    }


    @Selector()
    static aspectFunctions(state: RdsStateModel): Array<AspectFunction> {
        return state.functions;
    }

    @Selector()
    static aspectSystems(state: RdsStateModel): Array<AspectSystem> {
        return state.systems;
    }

    @Selector()
    static rdsLocation(state: RdsStateModel): Array<string> {
        return state.rdsLocations;
    }

    @Selector()
    static rdsBuildings(state: RdsStateModel): Array<string> {
        return state.rdsBuildings;
    }

    @Selector()
    static rdsFloors(state: RdsStateModel): Array<string> {
        return state.rdsFloors;
    }

    @Selector()
    static rdsRooms(state: RdsStateModel): Array<string> {
        return state.rdsRooms;
    }

    @Selector()
    static rdsSegments(state: RdsStateModel): Array<string> {
        return state.rdsSegments;
    }


    @Action(Rds.LoadAspectFunctions)
    loadAspectFunctions(ctx: StateContext<RdsStateModel>) {
        return this.aspectFunctionService.getAspectFunctions({page: 0, size: 1000}).pipe(tap(n => {
            ctx.patchState({functions: n.content});
        }))
    }

    @Action(Rds.LoadAspectSystems)
    loadAspectSystems(ctx: StateContext<RdsStateModel>) {
        return this.aspectSystemService.getAspectSystems({page: 0, size: 1000}).pipe(tap(n => {
            ctx.patchState({systems: n.content});
        }))
    }

    @Action(Rds.LoadAspectLocation)
    loadAspectLocation(ctx: StateContext<RdsStateModel>) {
        return this.aspectLocationService.getAspectLocation().pipe(tap(loc => {
                ctx.patchState({
                    rdsCountries: loc.countries,
                    rdsLocations: loc.locations,
                    rdsBuildings: loc.buildings,
                    rdsFloors: loc.floors,
                    rdsRooms: loc.rooms,
                    rdsSegments: loc.segments,
                });
            })
        );
    }
}
